<script>
	import { fade, fly } from "svelte/transition";
	import { onMount } from "svelte";
	let ready = false;
	onMount(() => (ready = true));
</script>

{#if ready}
	<div id="home">
		<div in:fade={{ duration: 2500 }}>
			<h1>CITY<span class="crimson">GUESSIN</span></h1>
			<pre>Something new is coming</pre>
		</div>
		<div id="link" transition:fade={{ delay: 2500, duration: 1500 }}>
			<a title="CityGuessin" href="https://cityguessin.vercel.app">Play here in the meanwhile</a>
		</div>
	</div>
{/if}

<style>
	h1 {
		color: dodgerblue;
		font-size: 6rem;
		font-weight: 100;
	}
	.crimson {
		color: crimson;
	}
	pre {
		font-size: 2rem;
	}
	#link {
		margin-top: 24px;
	}
	a {
		color: lightcyan;
	}
	a:hover {
		color: dodgerblue;
	}
</style>
