<script>
	import router from "page";

	import Home from "./pages/Home.svelte";

	let page;
	let params = {};

	router(
		"/",
		(ctx, next) => {
			params = ctx.params;
			next();
		},
		() => (page = Home)
	);
	router.start();

	function isObjectEmpty(object) {
		return Object.keys(object).length === 0 && object.constructor === Object;
	}
</script>

{#if isObjectEmpty(params)}
	<svelte:component this={page} />
{:else}
	<svelte:component this={page} {params} />
{/if}
